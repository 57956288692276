import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import NonStretchedImage from '../../components/layout/NonStretchedImage';

import { ViewportBreakpoints } from '../../components/cssConstants';

import FeatureListBreaker, { Features } from '../../components/cta/FeatureListBreaker';
import GetStarted from '../../components/cta/GetStarted';
import FeatureRow from '../../components/section/FeatureRow';

import HeroH1 from '../../components/hero/HeroH1';
import HeroH2 from '../../components/hero/HeroH2';

import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';

const DarkWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand180};
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 1440px 519px;
  background-image: url(${props => props.backgroundImage});
`;

const Hero = styled.div`
  padding: 80px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 120px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 45%;
    margin-right: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;

  .gatsby-image-wrapper {
    width: 100%;
    margin: 0 auto;
  }
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const Subtitle = styled(Text)`
  margin: 20px 0 40px 0;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme: { colors } }) => colors.brand160};
`;

const header = {
  backgroundColor: 'brand180',
  invertColors: true,
};

const Page = ({ data }) => (
  <Body header={header}>
    <SEO
      title="Staff scheduling - Rotaready"
      description="Remove the hassle from employee scheduling with software that remembers all of your requirements so you can build accurate staff rotas automatically."
      url="rota-scheduling"
    />
    <DarkWrapper
      backgroundImage={data.heroBackgroundMask.childImageSharp.fixed.src}
      title="Staff scheduling"
    >
      <ResponsiveContainer>
        <Hero>
          <HeroInnerContainer>
            <HeroBody>
              <HeroH1 color="brand" text="Rota scheduling" />
              <HeroH2 color="white" text="Perfect staff rotas, every time" />

              <H4 uistyle="white">
                Ensuring your business is appropriately staffed to meet demand
                is easier said than done. Rotaready removes the hassle from scheduling
                employees by giving you the ability to build perfect staff rotas automatically.
              </H4>

              <CtaButtons>
                <Link to="/demo">
                  <Button uistyle="primary" text="Request demo" size="lg" />
                </Link>
                <Link to="/contact">
                  <Button uistyle="white" text="Speak to the team" size="lg" ghost borderless />
                </Link>
              </CtaButtons>
            </HeroBody>

            <HeroIllustration>
              <NonStretchedImage fluid={data.heroImage.childImageSharp.fluid} />
            </HeroIllustration>
          </HeroInnerContainer>
        </Hero>
      </ResponsiveContainer>
    </DarkWrapper>

    <FeatureListBreaker
      rows={1}
      excludeFeature={Features.ROTA_SCHEDULING}
      headingText="Other features"
    />

    <FeatureRow
      mirrorArrange
      title="Build staff rotas in minutes, not hours"
      illustrationImage={data.generateRotaIllustration.childImageSharp.fluid}
      illustrationAltText="Automatically generating a staff rota within Rotaready"
    >
      <Subtitle text="Rotaready has a whole host of tools to help you schedule in record time, so you can get back to running your business." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Generate rotas automatically or build by hand" />
        <TextListItem text="Clone previous rotas" />
        <TextListItem text="All your changes are instantly validated to check staff availability, skills, contractual hours and regulatory compliance" />
      </UL>
    </FeatureRow>

    <FeatureRow
      title="An amazing editing experience"
      illustrationImage={data.addShiftIllustration.childImageSharp.fluid}
      illustrationAltText="Adding a new shift to a staff rota"
    >
      <Subtitle text="We spent years designing and perfecting our rota editor. It's beautifully simple to use yet packed full of intelligent features. No easy feat if we say so ourselves!" />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Drag and drop everything, from shifts to people" />
        <TextListItem text="View a day, week or month at a glance" />
        <TextListItem text="Easily switch between departments and sites" />
        <TextListItem text="Live charts of staffing levels, revenue, bookings and more" />
        <TextListItem text="Optimised for desktop, mobile and tablet" />
      </UL>
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Data driven scheduling"
      illustrationImage={data.hoursCostIllustration.childImageSharp.fluid}
      illustrationAltText="Popover showing hours, wage costs, revenue and the weather, with events to help build a rota in-line with demand"
    >
      <Subtitle text="With a view on all the factors that influence demand, it's easy to optimise your schedule and your labour spend." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Hours and wage costs updated in real-time as you make changes to the rota" />
        <TextListItem text="See a true cost of labour, inclusive of social taxes and smoothed holiday costs" />
        <TextListItem text="Powerful budgeting and analysis" />
        <TextListItem text="Forecasted and actual revenue, live from your EPOS system" />
        <TextListItem text="Live local weather forecasts and upcoming local events" />
      </UL>
    </FeatureRow>

    <FeatureRow
      title="Communication is key"
      illustrationImage={data.employeeCommunicationIllustration.childImageSharp.fluid}
      illustrationAltText="A push notification alerting an employee to a shift time change"
    >
      <Subtitle text="Rota software that engages your people with real-time updates, and empowers them with flexible shift swaps." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Instant, context-rich shift updates" />
        <TextListItem text="Free Rotaready app for the whole team, available on iOS and Android" />
        <TextListItem text="Free text message, email and push notifications" />
        <TextListItem text="Call in staff last minute with Shift Broadcast" />
      </UL>
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Fully compliant"
      illustrationImage={data.documentStorageIllustration.childImageSharp.fluid}
      illustrationAltText="Uploading an employee's passport to Rotaready"
    >
      <Subtitle text="We've got your back. We make sure your rotas tick all the boxes when it comes to compliance." />

      <UL size="xl" uistyle="normal" iconUistyle="primary">
        <TextListItem text="Working Time regulation compliance" />
        <TextListItem text="Rotas that adhere to employee contractual terms, skills and guaranteed hours" />
        <TextListItem text="Shifts only allocated to employees with up to date records and uploaded documentation" />
        <TextListItem text="Optional rota approval at manager level before shifts are published" />
        <TextListItem text="Powerful Tronc tool for managing and distributing tips" />
      </UL>
    </FeatureRow>

    <GetStarted />
  </Body>
);

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "illustrations/rota-scheduling-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 714, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    heroBackgroundMask: file(relativePath: { eq: "misc/hero-mask-brand180.png" }) {
      childImageSharp {
        fixed(height: 519, width: 1440, quality: 100) {
          src
        }
      }
    }

    generateRotaIllustration: file(relativePath: { eq: "illustrations/generate-rota.png" }) {
      childImageSharp {
        fluid(maxWidth: 480, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    addShiftIllustration: file(relativePath: { eq: "illustrations/rota-add-shift.png" }) {
      childImageSharp {
        fluid(maxWidth: 471, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    hoursCostIllustration: file(relativePath: { eq: "illustrations/hours-cost-events.png" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    employeeCommunicationIllustration: file(relativePath: { eq: "illustrations/employee-communication.png" }) {
      childImageSharp {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    documentStorageIllustration: file(relativePath: { eq: "illustrations/document-storage.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
